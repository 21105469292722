import { IncrementalSearchRegionTypeInput, IncrementalSearchResponse, useIncrementalSearchRegionLazyQuery } from 'generated/graphql'
import { SearchHotelFormKeywordTypes, convertKeywordTypeToSearchRegionTypes } from 'helper/searchHotelParams'
import { debounce } from 'lib/debounce'
import { useCallback, useMemo, useRef, useState } from 'react'

export const useIncrementalSearchRegion = ({
  keywordType,
  limit,
  delayMilliseconds,
}: {
  keywordType: SearchHotelFormKeywordTypes
  limit: number
  delayMilliseconds: number
}) => {
  const keywordRef = useRef<string>('')
  const [searchResult, setSearchResult] = useState<IncrementalSearchResponse>()
  const [fetch, { error, variables, loading: searching }] = useIncrementalSearchRegionLazyQuery({
    onCompleted: data => {
      setSearchResult(keywordRef.current.length === 0 ? { data: [] } : data?.incrementalSearchRegion)
    },
  })

  const regionTypes = useMemo<IncrementalSearchRegionTypeInput[]>(() => convertKeywordTypeToSearchRegionTypes(keywordType), [keywordType])

  const searchDebounce = useMemo(
    () =>
      debounce(async (keyword: string) => {
        // typesで引っかかる種類減らせる
        fetch({ variables: { input: { keyword, limit, types: regionTypes } } })
      }, delayMilliseconds),
    [limit, regionTypes]
  )

  const search = useCallback(
    (keyword: string) => {
      keywordRef.current = keyword

      if (keyword.length === 0) {
        setSearchResult({ data: [] })
        return
      }

      // keywordが前回のデータと同じだったら何もしない
      if (keyword === variables?.input?.keyword) return

      // キーワードが変わっていたら表示をリセット
      setSearchResult({ data: [] })
      searchDebounce(keyword)
    },
    [searchDebounce, variables?.input?.keyword]
  )

  return { search, searchResult, loading: searching, error }
}
