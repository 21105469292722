// QueryStringから型安全に値を取り出すためのヘルパー

import { publicEnv } from 'env'
import { compactMap } from 'helper/array'
import { HotelOccupancy } from 'types/occupancy'

export const QueryHelper = {
  getNumber: (string: string | string[] | undefined) => {
    const text = QueryHelper.getStringOrFirst(string)
    if (!text) return
    return Number(text)
  },
  getStringOrFirst: (string: string | string[] | undefined): string | undefined => {
    if (!string) return
    if (Array.isArray(string)) return string[0]
    return string
  },
  getArray: (string: string | string[] | undefined) => {
    if (!string) return
    return Array.isArray(string) ? string : [string]
  },
  getEnumOrFirst: <Enum extends readonly string[]>(string: string | string[] | undefined, enumValues: Enum): Enum[number] | undefined => {
    if (!string) return
    const value = Array.isArray(string) ? string[0] : string
    if (enumValues.includes(value)) {
      return value as Enum[number]
    }
    if (publicEnv.env !== 'prod') {
      // eslint-disable-next-line no-console
      console.warn(`unknown enum values(cases are ${enumValues}) found: ${value}`)
    }
    return
  },
  getEnumArray: <Enum extends readonly string[]>(string: string | string[] | undefined, enumValues: Enum): Enum[number][] | undefined => {
    if (!string) return
    const array = Array.isArray(string) ? string : [string]
    const enumList: Enum[number][] = []
    array.forEach(value => {
      if (enumValues.includes(value)) {
        enumList.push(value as Enum[number])
      } else if (publicEnv.env !== 'prod') {
        // eslint-disable-next-line no-console
        console.warn(`unknown enum values(cases are ${enumValues}) found: ${value}`)
      }
    })
    if (enumList.length === 0) return
    return enumList
  },
  getAsOccupancies: (string: string | string[] | undefined): HotelOccupancy[] | undefined => {
    if (!string) return
    const array = Array.isArray(string) ? string : [string]
    const occupancies = compactMap(array, (text): HotelOccupancy | undefined => {
      const splits = text.split('-')
      if (![1, 2].includes(splits.length)) return
      const adult = Number(splits[0])
      const childAges = compactMap((splits[1] ?? '').split(','), age => (age.length > 0 ? Number(age) : undefined))
      return { adult, childAges }
    })
    if (occupancies.length === 0) return
    return occupancies
  },
  buildOccupanciesParams: (occupancies: HotelOccupancy[] | undefined): string[] => {
    return occupancies?.map(o => `${o.adult}${o.childAges.length > 0 ? `-${o.childAges.map(a => `${a}`).join(',')}` : ''}`) ?? []
  },
  buildBoolean: (bool: boolean): string => {
    return bool ? '1' : '0'
  },
  getBoolean: (string: string | string[] | undefined): boolean | undefined => {
    const first = QueryHelper.getStringOrFirst(string)
    if (!first) return
    return first === '1'
  },
}
