import { createIcon } from '@chakra-ui/icon'

export const CalendarIcon = createIcon({
  path: (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.7507 4.09119H18.0271V3.21858C18.0316 3.09261 18.0107 2.96702 17.9657 2.8493C17.9206 2.73158 17.8523 2.62415 17.7647 2.53343C17.6772 2.44271 17.5723 2.37055 17.4563 2.32127C17.3403 2.27198 17.2155 2.24658 17.0895 2.24658C16.9634 2.24658 16.8387 2.27198 16.7226 2.32127C16.6066 2.37055 16.5017 2.44271 16.4142 2.53343C16.3267 2.62415 16.2584 2.73158 16.2133 2.8493C16.1682 2.96702 16.1473 3.09261 16.1518 3.21858V4.09119H7.84867V3.21858C7.85321 3.09261 7.83231 2.96702 7.78723 2.8493C7.74216 2.73158 7.67382 2.62415 7.58631 2.53343C7.49879 2.44271 7.39389 2.37055 7.27787 2.32127C7.16185 2.27198 7.03709 2.24658 6.91104 2.24658C6.78499 2.24658 6.66023 2.27198 6.54421 2.32127C6.42819 2.37055 6.32329 2.44271 6.23577 2.53343C6.14826 2.62415 6.07992 2.73158 6.03484 2.8493C5.98977 2.96702 5.96887 3.09261 5.97341 3.21858V4.09119H3.24984C3.08551 4.09103 2.92276 4.12326 2.77089 4.18604C2.61902 4.24882 2.48102 4.34091 2.36476 4.45705C2.24851 4.57319 2.15628 4.71111 2.09336 4.86292C2.03043 5.01472 1.99805 5.17744 1.99805 5.34177V20.4751C1.99805 20.6395 2.03043 20.8022 2.09336 20.954C2.15628 21.1058 2.24851 21.2437 2.36476 21.3599C2.48102 21.476 2.61902 21.5681 2.77089 21.6309C2.92276 21.6936 3.08551 21.7259 3.24984 21.7257H20.7507C21.0821 21.7254 21.3999 21.5935 21.6342 21.359C21.8684 21.1245 22 20.8066 22 20.4751V5.34177C22 5.01031 21.8684 4.6924 21.6342 4.45791C21.3999 4.22342 21.0821 4.09152 20.7507 4.09119ZM20 20H4V9.22234H20V20Z"
        fill="#2C67AC"
      />
      <path d="M8.74921 11.1348H6.24805V13.6359H8.74921V11.1348Z" fill="#0069B2" />
      <path d="M13.2502 11.1348H10.749V13.6359H13.2502V11.1348Z" fill="#0069B2" />
      <path d="M17.7516 11.1348H15.2505V13.6359H17.7516V11.1348Z" fill="#0069B2" />
      <path d="M8.74921 15.636H6.24805V18.1371H8.74921V15.636Z" fill="#0069B2" />
      <path d="M13.2502 15.636H10.749V18.1371H13.2502V15.636Z" fill="#0069B2" />
      <path d="M17.7516 15.636H15.2505V18.1371H17.7516V15.636Z" fill="#0069B2" />
    </svg>
  ),
})
