import { Flex, Icon, Text } from '@chakra-ui/react'
import { AirportIcon } from 'components/icons/airportIcon'
import { IncrementalSearchResponseData } from 'generated/graphql'
import { FC } from 'react'
import { MdBusiness, MdLocationPin, MdTrain } from 'react-icons/md'

type Props = {
  data: IncrementalSearchResponseData
  onClick: () => any
}

export const IncrementalSearchListItem: FC<Props> = ({ data, onClick }) => {
  return (
    <Flex key={data.id} alignItems="center" py={2} px={3} cursor="pointer" _hover={{ background: 'blue.50' }} onClick={onClick}>
      <Icon
        as={(() => {
          switch (data.type) {
            case 'train_station':
              return MdTrain
            case 'airport':
              return AirportIcon
            case 'property_content':
              return MdBusiness
            default:
              return MdLocationPin
          }
        })()}
        w={6}
        h={6}
        color="blue.500"
      />
      <Flex direction="column" ml="4">
        <Text fontWeight="bold">
          {data.name}
          {data.type === 'multi_city_vicinity' ? '（およびその周辺）' : ''}
        </Text>
        {data.location && <Text>{data.location}</Text>}
      </Flex>
    </Flex>
  )
}
