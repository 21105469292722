import {
  IncrementalSearchRegionTypeInput,
  RateAmenitiesMealType,
  SearchPropertyContentCategory,
  SearchPropertyContentInputFacility,
  SearchPropertyContentRatings,
  SearchPropertyContentSortField,
  SortDirection,
} from 'generated/graphql'
import {
  SearchHotelFormKeywordTypes,
  SearchHotelParams,
  convertIncrementalSearchDataTypeToKeywordType,
  hotelCategories,
  hotelFacilityTypes,
  mealTypes,
  regionTypes,
  searchHotelFormKeywordTypes,
  searchHotelStarRatings,
} from 'helper/searchHotelParams'
import { MakeFieldOptional } from 'types/makeFieldOptional'
import { HotelOccupancy } from 'types/occupancy'
import { QueryString } from 'types/queryString'
import { HotelQueryParamsMaster } from './_master'
import { QueryHelper } from './helper'

export const searchHotelSortFields: readonly SearchPropertyContentSortField[] = ['inclusivePrice', 'recommend'] as const
const sortDirections: readonly SortDirection[] = ['asc', 'desc'] as const

export type SearchHotelQueryParams = Pick<HotelQueryParamsMaster, 'ci' | 'co' | 'ocs'> & {
  pn?: string // page number for pagination
  kw: string // keyword
  kt: string // keyword type
  rid?: string // region id
  rt?: string // region type
  lat?: string // latitude 緯度
  lon?: string // longitude 軽度
  pid?: string // property content id
  sf?: string // sort field
  sd?: string // sort direction
  sr?: string | string[] // star ratings
  lp?: string // lower limit price 下限
  up?: string // upper limit price 上限
  cs?: string | string[] // categories
  mgr?: string // min guest ratings
  mt?: string // meal type
  fs?: string | string[] // hotel facilities
  cvd?: string // covid-19 prevention コロナ対策
}

export type SearchHotelQueryParsedParams = MakeFieldOptional<{
  pageNo: number
  keyword: string
  keywordType: SearchHotelFormKeywordTypes
  checkin: string
  checkout: string
  occupancies: HotelOccupancy[]
  regionId: string
  regionType: IncrementalSearchRegionTypeInput
  coordinates: {
    latitude: number
    longitude: number
  }
  propertyContentId: string
  sortField: SearchPropertyContentSortField
  sortDirection: SortDirection
  stars: SearchPropertyContentRatings[]
  minPrice: number
  maxPrice: number
  categories: SearchPropertyContentCategory[]
  facilities: SearchPropertyContentInputFacility[]
  covid19Prevention: boolean
  minGuestRatings: number
  mealType: RateAmenitiesMealType
}>

export const SearchHotelQuery = {
  build: (data: SearchHotelParams): SearchHotelQueryParams => {
    const params: SearchHotelQueryParams = {
      kw: data.spot ? data.spot.name : data.keyword,
      kt: data.spot ? convertIncrementalSearchDataTypeToKeywordType(data.spot.type) : data.keywordType,
      ci: data.checkin,
      co: data.checkout,
      ocs: QueryHelper.buildOccupanciesParams(data.occupancies),
    }

    params.pid = data.spot ? undefined : data.propertyContentId
    params.rid = data.spot ? data.spot.id : data.regionId
    params.rt = data.regionType

    if (data.spot) {
      params.lat = data.spot.coordinates.latitude.toString()
      params.lon = data.spot.coordinates.longitude.toString()
      params.rt = data.spot.type
    } else if (data.coordinates) {
      params.lat = data.coordinates.latitude.toString()
      params.lon = data.coordinates.longitude.toString()
    }

    if (data.sortField) params.sf = data.sortField
    if (data.sortDirection) params.sd = data.sortDirection

    if (data.stars && data.stars.length > 0) params.sr = data.stars
    if (data.minPrice) params.lp = data.minPrice.toString()
    if (data.maxPrice) params.up = data.maxPrice.toString()
    if (data.categories && data.categories.length > 0) params.cs = data.categories
    if (data.facilities && data.facilities.length > 0) params.fs = data.facilities
    if (data.minGuestRatings) params.mgr = data.minGuestRatings.toString()
    if (data.mealType) params.mt = data.mealType
    if (data.covid19Prevention) params.cvd = QueryHelper.buildBoolean(data.covid19Prevention)

    if (data.pageNo) params.pn = data.pageNo.toString()

    return params
  },
  parse: (query: QueryString): SearchHotelQueryParsedParams => {
    const q = query as Partial<Record<keyof SearchHotelQueryParams, string | string[]>>
    const lat = QueryHelper.getNumber(q.lat)
    const lon = QueryHelper.getNumber(q.lon)

    return {
      pageNo: QueryHelper.getNumber(q.pn),
      keyword: QueryHelper.getStringOrFirst(q.kw),
      keywordType: QueryHelper.getEnumOrFirst(q.kt, searchHotelFormKeywordTypes),
      checkin: QueryHelper.getStringOrFirst(q.ci),
      checkout: QueryHelper.getStringOrFirst(q.co),
      occupancies: QueryHelper.getAsOccupancies(q.ocs),
      regionId: QueryHelper.getStringOrFirst(q.rid),
      regionType: QueryHelper.getEnumOrFirst(q.rt, regionTypes),
      coordinates: lat && lon ? { latitude: lat, longitude: lon } : undefined,
      propertyContentId: QueryHelper.getStringOrFirst(q.pid),
      sortField: QueryHelper.getEnumOrFirst(q.sf, searchHotelSortFields),
      sortDirection: QueryHelper.getEnumOrFirst(q.sd, sortDirections),
      stars: QueryHelper.getEnumArray(q.sr, searchHotelStarRatings),
      minPrice: QueryHelper.getNumber(q.lp),
      maxPrice: QueryHelper.getNumber(q.up),
      categories: QueryHelper.getEnumArray(q.cs, hotelCategories),
      facilities: QueryHelper.getEnumArray(q.fs, hotelFacilityTypes),
      minGuestRatings: QueryHelper.getNumber(q.mgr),
      mealType: QueryHelper.getEnumOrFirst(q.mt, mealTypes),
      covid19Prevention: QueryHelper.getBoolean(q.cvd),
    }
  },
}
