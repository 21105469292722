import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { AirportIcon } from 'components/icons/airportIcon'
import { CalendarIcon } from 'components/icons/calendarIcon'
import { CustomSelect } from 'components/ui/customSelect'
import { addDays } from 'date-fns'
import { IncrementalSearchResponseData } from 'generated/graphql'
import { resetDateRange } from 'helper/date'
import { formatUtcDateStringInJST } from 'helper/dateFormat'
import {
  createOccupancies,
  defaultSearchHotelRoomParams,
  maxCheckinDaysInFuture,
  maxNightsOfStay,
  numOfAdults,
  numOfChildAges,
  numOfChildren,
  numOfRooms,
  searchHotelFormDefaultData,
  SearchHotelFormInput,
  searchHotelFormKeywordTypes,
  SearchHotelFormKeywordTypes,
} from 'helper/searchHotelParams'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdBusiness, MdLocationPin, MdMeetingRoom, MdOutlineChildCare, MdPerson, MdTrain } from 'react-icons/md'
import { AutocompleteInputField } from '../autocompleteInputField'
import { CustomDateRange } from '../customDateRange'
import { CustomHeading } from '../customHeading'
import { useSearchHotelForm } from './useSearchHotelForm'

const getKeywordTypeLabel = (type: SearchHotelFormKeywordTypes): string => {
  switch (type) {
    case 'area':
      return 'エリア'
    case 'hotel':
      return 'ホテル'
    case 'spot':
      return 'スポット'
    case 'station':
      return '駅'
    case 'airport':
      return '空港'
  }
}

export type SearchHotelFormProps = {
  onSubmit: (data: SearchHotelFormInput) => void
  defaultValues?: Partial<SearchHotelFormInput>
  submitting?: boolean
}

export const SearchHotelForm: FC<SearchHotelFormProps> = ({ onSubmit, defaultValues, submitting }) => {
  const { register, handleSubmit, getValues, setValue, watch } = useForm<SearchHotelFormInput>({
    mode: 'onSubmit',
    defaultValues: {
      keyword: defaultValues?.keyword ?? searchHotelFormDefaultData.keyword(),
      keywordType: defaultValues?.keywordType ?? searchHotelFormDefaultData.keywordType(),
      coordinates: defaultValues?.coordinates,
      ...defaultSearchHotelRoomParams({
        checkin: defaultValues?.checkin,
        checkout: defaultValues?.checkout,
        occupancies: defaultValues?.occupancies,
      }),
    },
  })
  const {
    incrementalSearch,
    resetState,
    loading: incrementalLoading,
    data: incrementalSearchData,
  } = useSearchHotelForm({ keywordType: watch('keywordType') })
  const [selectedIncrementalSearchData, setSelectedIncrementalSearchData] = useState<IncrementalSearchResponseData>()
  const [incrementalSearchKeyword, setIncrementalSearchKeyword] = useState<string>(getValues('keyword'))
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false)
  const dateDisclosure = useDisclosure()
  const [errorMessage, setErrorMessage] = useState<string>()

  const _onSubmit = useCallback(
    async (data: SearchHotelFormInput) => {
      const coordinates = selectedIncrementalSearchData?.coordinates ?? data.coordinates
      if (!coordinates) {
        setErrorMessage('入力欄に表示されたエリアまたはホテルを選択してください')
        return
      }
      setErrorMessage(undefined)

      onSubmit({
        ...data,
        occupancies: data.occupancies.map(o => ({ adult: Number(o.adult), childAges: o.childAges })),
        coordinates,
        propertyContentId: data.keywordType === 'hotel' ? selectedIncrementalSearchData?.id ?? defaultValues?.propertyContentId : undefined,
        regionId:
          selectedIncrementalSearchData?.type !== 'property_content'
            ? selectedIncrementalSearchData?.id ?? defaultValues?.regionId
            : undefined,
        regionType:
          selectedIncrementalSearchData?.type === 'property_content'
            ? undefined
            : selectedIncrementalSearchData?.type ?? defaultValues?.regionType,
      })
      return false
    },
    [onSubmit, selectedIncrementalSearchData]
  )

  // checkinが変わったときにcheckoutの日付を超えてたら、checkoutをinの翌日にする
  useEffect(() => {
    const checkout = resetDateRange(getValues('checkin'), getValues('checkout'))
    if (checkout) {
      setValue('checkout', checkout)
    }
  }, [watch('checkin'), getValues, setValue])

  useEffect(() => {
    setValue('keyword', '')
    setIncrementalSearchKeyword('')
    setSelectedIncrementalSearchData(undefined)
  }, [watch('keywordType')])

  // 検索ワードが変わったり、インクリメンタルサーチのBoxが表示されたら検索する
  useEffect(() => {
    if (!isAutocompleteOpen) {
      if (incrementalSearchKeyword.length === 0) resetState()
      return
    }
    const keywordType = getValues('keywordType')
    incrementalSearch(incrementalSearchKeyword, keywordType)
  }, [incrementalSearchKeyword, getValues, incrementalSearch, isAutocompleteOpen])

  useEffect(() => {
    if (defaultValues?.keyword) setValue('keyword', defaultValues.keyword)
    if (defaultValues?.keywordType) {
      setValue('keywordType', defaultValues.keywordType)
    }
  }, [defaultValues])

  const keywordPlaceholder = useMemo(() => {
    const type = watch('keywordType')
    return `${getKeywordTypeLabel(type)}名を入力`
  }, [watch('keywordType')])

  return (
    <Box position="relative">
      <Image src="/assets/pages/hotels/cloud1.svg" position="absolute" top="92px" left="-102px" w="152px" h="77px" zIndex={0} />
      <Image src="/assets/pages/hotels/cloud2.svg" position="absolute" bottom="0" right="-103px" w="99px" h="54px" zIndex={0} />
      <Image src="/assets/pages/hotels/cloud3.svg" position="absolute" bottom="-66px" left="478px" w="345px" h="58px" zIndex={0} />

      <Flex
        direction="column"
        rounded="lg"
        background="gray.10"
        boxShadow="0px 1px 7.007999897003174px 0px #000B264D"
        position="relative"
        zIndex={1}
        py={5}
        px={7}
      >
        <Flex mb={6}>
          <CustomHeading as="h5" underline>
            ホテルを探す
          </CustomHeading>
        </Flex>
        <form onSubmit={handleSubmit(_onSubmit)}>
          <Flex direction="column" justify="center">
            <HStack spacing={7} alignItems="flex-start">
              <FormControl>
                <FormLabel>目的地</FormLabel>
                <AutocompleteInputField
                  leftIcon={(() => {
                    switch (watch('keywordType')) {
                      case 'area':
                      case 'spot':
                        return MdLocationPin
                      case 'hotel':
                        return MdBusiness
                      case 'station':
                        return MdTrain
                      case 'airport':
                        return AirportIcon
                    }
                  })()}
                  placeholder={keywordPlaceholder}
                  defaultKeyword={watch('keyword')}
                  searching={incrementalLoading}
                  incrementalSearchedData={incrementalSearchData}
                  onIsAutocompleteOpenChanged={isOpen => setIsAutocompleteOpen(isOpen)}
                  onKeywordChanged={text => setIncrementalSearchKeyword(text)}
                  onDataClicked={data => {
                    setSelectedIncrementalSearchData(data)
                    setValue('keyword', data.name)
                    resetState()
                  }}
                />

                <Box mt={3} pl={4}>
                  <FormControl>
                    <RadioGroup defaultValue={defaultValues?.keywordType ?? searchHotelFormKeywordTypes[0]}>
                      <HStack spacing={4}>
                        {searchHotelFormKeywordTypes.slice(0, 3).map(type => (
                          <Radio key={type} value={type} {...register('keywordType')}>
                            {getKeywordTypeLabel(type)}
                          </Radio>
                        ))}
                      </HStack>
                      <HStack mt={3} spacing={4}>
                        {searchHotelFormKeywordTypes.slice(3, 5).map(type => (
                          <Radio key={type} value={type} {...register('keywordType')}>
                            {getKeywordTypeLabel(type)}
                          </Radio>
                        ))}
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                </Box>
              </FormControl>

              <Box position="relative">
                <Popover {...dateDisclosure}>
                  <PopoverTrigger>
                    <HStack spacing={3} cursor="pointer">
                      <FormControl>
                        <FormLabel>チェックイン</FormLabel>
                        <HStack background="white" border="1px solid" borderColor="blue.600" height={12} rounded="lg" w="152px" px={3}>
                          <Icon as={CalendarIcon} boxSize={6} color="blue.500" />
                          <Text fontSize={15}>{formatUtcDateStringInJST(new Date(getValues('checkin')), 'M月d日')}</Text>
                        </HStack>
                      </FormControl>
                      <FormControl>
                        <FormLabel>チェックアウト</FormLabel>
                        <HStack background="white" border="1px solid" borderColor="blue.600" height={12} rounded="lg" w="152px" px={3}>
                          <Icon as={CalendarIcon} boxSize={6} color="blue.500" />
                          <Text fontSize={15}>{formatUtcDateStringInJST(new Date(getValues('checkout')), 'M月d日')}</Text>
                        </HStack>
                      </FormControl>
                    </HStack>
                  </PopoverTrigger>
                  <PopoverContent>
                    <Box background="white" w="774px" zIndex={10} rounded="lg" borderColor="gray.40" borderWidth={1} overflow="hidden">
                      <CustomDateRange
                        defaultStartDate={getValues('checkin')}
                        defaultEndDate={getValues('checkout')}
                        maxDays={maxNightsOfStay}
                        maxStartDate={addDays(Date.now(), maxCheckinDaysInFuture)}
                        onChangeStartDate={value => setValue('checkin', value)}
                        onChangeEndDate={value => {
                          setValue('checkout', value)
                          dateDisclosure.onClose()
                        }}
                      />
                    </Box>
                  </PopoverContent>
                </Popover>
              </Box>

              <Box>
                <FormControl>
                  <FormLabel>部屋数</FormLabel>
                  <Box w="112px">
                    <CustomSelect
                      defaultValue={getValues('occupancies').length}
                      icon={<Icon as={MdMeetingRoom} boxSize={5} color="blue.500" />}
                      onChange={e => {
                        const num = Number(e.target.value)
                        if (!Number.isInteger(num)) return
                        const occupancies = getValues('occupancies')
                        const length = occupancies.length
                        if (length < num) {
                          setValue('occupancies', [...occupancies, ...createOccupancies(num - length)])
                        } else if (length > num) {
                          setValue('occupancies', occupancies.slice(0, num))
                        }
                      }}
                    >
                      {numOfRooms.map(num => (
                        <option key={num} value={num}>
                          {num}室
                        </option>
                      ))}
                    </CustomSelect>
                  </Box>
                </FormControl>
              </Box>
              <VStack spacing={4}>
                {watch('occupancies').map((occupancy, i) => (
                  <Box key={`occupancy_${i}`}>
                    <Flex alignItems="flex-end">
                      <Text fontSize={14} lineHeight={'14px'} mr={2} mb={'13px'} whiteSpace="nowrap">
                        客室{i + 1}
                      </Text>
                      <HStack spacing={4}>
                        <FormControl>
                          <FormLabel>大人</FormLabel>
                          <Box w="112px">
                            <CustomSelect
                              {...register(`occupancies.${i}.adult`)}
                              defaultValue={occupancy.adult}
                              icon={<Icon as={MdPerson} boxSize={5} color="blue.500" />}
                            >
                              {numOfAdults.map(num => (
                                <option key={`adult_${num}`} value={num}>
                                  {num}名
                                </option>
                              ))}
                            </CustomSelect>
                          </Box>
                        </FormControl>
                        <FormControl>
                          <FormLabel>子供</FormLabel>
                          <Box w="112px">
                            <CustomSelect
                              defaultValue={occupancy.childAges?.length || 0}
                              icon={<Icon as={MdOutlineChildCare} boxSize={5} color="blue.500" />}
                              onChange={e => {
                                const num = Number(e.target.value)
                                if (!Number.isInteger(num)) return
                                const occupancies = getValues('occupancies')

                                const length = occupancies[i].childAges.length
                                if (length < num) {
                                  occupancies[i].childAges = [...Array(num)].map(() => 0)

                                  setValue('occupancies', [...occupancies])
                                } else if (length > num) {
                                  occupancies[i].childAges = occupancies[i].childAges.slice(0, num)

                                  setValue('occupancies', [...occupancies])
                                }
                              }}
                            >
                              {numOfChildren.map(num => (
                                <option key={`children_${num}`} value={num}>
                                  {num}名
                                </option>
                              ))}
                            </CustomSelect>
                          </Box>
                        </FormControl>
                      </HStack>
                    </Flex>
                    {occupancy.childAges && (
                      <VStack alignItems="flex-end" mt={2}>
                        {occupancy.childAges.map((childAge, j) => {
                          return (
                            <FormControl key={`childAge_${i}_${j}`} w="112px">
                              <Box>
                                <CustomSelect defaultValue={childAge} {...register(`occupancies.${i}.childAges.${j}`)}>
                                  {numOfChildAges.map(num => (
                                    <option key={`child_ages_${num}`} value={num}>
                                      {num}歳
                                    </option>
                                  ))}
                                </CustomSelect>
                              </Box>
                            </FormControl>
                          )
                        })}
                      </VStack>
                    )}
                  </Box>
                ))}
              </VStack>
            </HStack>

            <Flex mt={-2} direction="column" justify="center" align="center">
              <Button type="submit" fontSize={16} w="284px" isLoading={submitting}>
                検索
              </Button>
              {errorMessage && (
                <Text mt="4" textColor="red.400">
                  {errorMessage}
                </Text>
              )}
            </Flex>
          </Flex>
        </form>
      </Flex>
    </Box>
  )
}
