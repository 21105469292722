import { Box, Flex, forwardRef, Select, SelectFieldProps } from '@chakra-ui/react'
import { ReactElement, ReactNode } from 'react'
import { MdArrowDropDown } from 'react-icons/md'

type CustomSelectProps = {
  children: ReactNode
  icon?: ReactElement
} & SelectFieldProps

export const CustomSelect = forwardRef<CustomSelectProps, typeof Select>(({ children, icon, ...props }, ref) => {
  return (
    <Box position="relative">
      {icon && (
        <Flex alignItems="center" position="absolute" top={0} left={'14px'} h="full" zIndex={1} pointerEvents="none">
          {icon}
        </Flex>
      )}
      <Select {...props} ref={ref} icon={<MdArrowDropDown />} variant={icon && 'withIcon'}>
        {children}
      </Select>
    </Box>
  )
})
