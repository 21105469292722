import { Box, BoxProps, Icon, Input, InputGroup, InputLeftElement, useDisclosure } from '@chakra-ui/react'
import { IncrementalSearchResponseData } from 'generated/graphql'
import { FC, useEffect, useRef } from 'react'
import { IncrementalSearchListItem } from '../incremetalSearchListItem'
import { Loading } from '../loading'

export type AutocompleteInputFieldProps = {
  leftIcon?: any
  placeholder?: string
  defaultKeyword?: string
  incrementalSearchedData?: IncrementalSearchResponseData[]
  searching?: boolean
  autocompletePopoverWidth?: BoxProps['w']
  onIsAutocompleteOpenChanged?: (isOpen: boolean) => any
  onKeywordChanged?: (keyword: string) => any
  onDataClicked?: (data: IncrementalSearchResponseData) => any
}

export const AutocompleteInputField: FC<AutocompleteInputFieldProps> = ({
  leftIcon,
  placeholder,
  defaultKeyword,
  searching,
  autocompletePopoverWidth,
  onIsAutocompleteOpenChanged,
  onKeywordChanged,
  incrementalSearchedData,
  onDataClicked,
}) => {
  const suggestDisclosure = useDisclosure()
  const backgroundInputRef = useRef<HTMLInputElement | null>(null)
  const popOverBoxRef = useRef<HTMLDivElement | null>(null)

  // インクリメンタルサーチのBox外を選択したらBoxを閉じる
  useEffect(() => {
    const onClosePopoverBox = (e: MouseEvent) => {
      if (!popOverBoxRef.current?.contains(e.target as Node)) suggestDisclosure.onClose()
    }
    window.addEventListener('click', onClosePopoverBox)
    return () => window.removeEventListener('click', onClosePopoverBox)
  }, [])

  useEffect(() => {
    onIsAutocompleteOpenChanged?.(suggestDisclosure.isOpen)
  }, [suggestDisclosure.isOpen])

  return (
    <Box position="relative" ref={popOverBoxRef}>
      <InputGroup>
        {leftIcon && (
          <InputLeftElement pointerEvents="none">
            <Icon as={leftIcon} w={6} h={6} color="blue.600" />
          </InputLeftElement>
        )}
        <Input
          ref={backgroundInputRef}
          value={defaultKeyword}
          placeholder={placeholder}
          readOnly
          onClick={() => {
            suggestDisclosure.onOpen()
          }}
        />
      </InputGroup>

      {suggestDisclosure.isOpen && (
        <Box
          position="absolute"
          top={'-1px'}
          left={'-1px'}
          right={'-1px'}
          bgColor="white"
          zIndex={10}
          rounded={'lg'}
          boxShadow="md"
          w={autocompletePopoverWidth}
        >
          <Box borderBottomWidth={1} borderColor="gray.50">
            <Input
              defaultValue={defaultKeyword}
              placeholder={placeholder}
              border="none"
              _focus={{ border: 'none' }}
              autoFocus
              onChange={e => {
                if (!suggestDisclosure.isOpen) return
                onKeywordChanged?.(e.target.value)
              }}
            />
          </Box>
          {/* 1つのItemがだいたい64pxなので、64 x 10 = 640pxで、スクロールできる感を出すために32px引いている */}
          <Box overflowY="scroll" maxH="608px">
            {incrementalSearchedData?.map((data: IncrementalSearchResponseData) => (
              <IncrementalSearchListItem
                key={data.id}
                data={data}
                onClick={() => {
                  onDataClicked?.(data)
                  suggestDisclosure.onClose()
                }}
              />
            ))}
            {searching && <Loading />}
          </Box>
        </Box>
      )}
    </Box>
  )
}
