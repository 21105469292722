import { addDays } from 'date-fns'
import { formatDate } from 'helper/dateFormat'

export const dateInputFormat = 'yyyy-MM-dd'

// return null if no reset needed
export const resetDateRange = (start: string, end: string): string | null => {
  const _start = new Date(start)
  const _end = new Date(end)

  if (_start.getTime() >= _end.getTime()) {
    // 新しいendとして翌日を返す
    return formatDate(addDays(_start, 1), dateInputFormat)
  } else {
    return null
  }
}
