import { IncrementalSearchResponseData } from 'generated/graphql'
import { SearchHotelFormKeywordTypes } from 'helper/searchHotelParams'
import { useIncrementalSearchPropertyContent } from 'hooks/search/useIncrementalSearchPropertyContent'
import { useIncrementalSearchRegion } from 'hooks/search/useIncrementalSearchRegion'
import { useCallback, useEffect, useState } from 'react'

const limit = 20
const delayMilliseconds = 300

export const useSearchHotelForm = ({ keywordType }: { keywordType: SearchHotelFormKeywordTypes }) => {
  const incrementalRegionState = useIncrementalSearchRegion({ limit, delayMilliseconds, keywordType })
  const incrementalHotelState = useIncrementalSearchPropertyContent({ limit, delayMilliseconds })
  const [currentKeywordType, setCurrentKeywordType] = useState<SearchHotelFormKeywordTypes>()
  const [data, setData] = useState<IncrementalSearchResponseData[]>([])

  const incrementalSearch = useCallback(
    (keyword: string, keywordType: SearchHotelFormKeywordTypes) => {
      switch (keywordType) {
        case 'hotel':
          setCurrentKeywordType(keywordType)
          incrementalHotelState.search(keyword)
          break
        case 'area':
        case 'spot':
        case 'station':
        case 'airport':
          setCurrentKeywordType(keywordType)
          incrementalRegionState.search(keyword)
          break
      }
    },
    [incrementalRegionState.search, incrementalHotelState.search]
  )

  const resetState = useCallback(() => {
    setData([])
  }, [setData])

  useEffect(() => {
    if (!currentKeywordType) return
    switch (currentKeywordType) {
      case 'hotel':
        setData(incrementalHotelState.searchResult?.data ?? [])
        break
      case 'area':
      case 'spot':
      case 'station':
      case 'airport':
        setData(incrementalRegionState.searchResult?.data ?? [])
        break
      case undefined:
        break
    }
  }, [currentKeywordType, incrementalRegionState.searchResult, incrementalHotelState.searchResult])

  return {
    incrementalSearch,
    resetState,
    data,
    loading: incrementalHotelState.loading || incrementalRegionState.loading,
    error: incrementalHotelState.error || incrementalRegionState.error,
  }
}
