import { Icon } from '@chakra-ui/icons'
import { Box, HStack, Text } from '@chakra-ui/react'
import { addDays, differenceInDays, max } from 'date-fns'
import locale from 'date-fns/locale/ja'
import { dateInputFormat } from 'helper/date'
import { formatDate, formatUtcDateStringInJST, parseDateStringAsUtc } from 'helper/dateFormat'
import { FC, useState } from 'react'
import { DateRange as DateRangeComponent } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { MdArrowForward } from 'react-icons/md'

type CustomDateRangeProps = {
  defaultStartDate?: string
  defaultEndDate?: string
  // rangeの開始日の最大
  maxStartDate?: Date
  // rangeの最大日数
  maxDays?: number
  onChangeStartDate: (startDate: string) => void
  onChangeEndDate: (endDate: string) => void
}

type DateRange = {
  startDate: Date
  endDate: Date
}

export const CustomDateRange: FC<CustomDateRangeProps> = ({
  defaultStartDate,
  defaultEndDate,
  maxStartDate,
  maxDays,
  onChangeStartDate,
  onChangeEndDate,
}) => {
  const [selectionMode, setSelectionMode] = useState<'start' | 'end'>('start')
  const [selectableDateRange, setSelectableDateRange] = useState<{ min?: Date; max?: Date }>({ min: new Date(), max: maxStartDate })
  const [dateRange, setDateRange] = useState<DateRange>({
    // UTC-7:00(アメリカとか)のTZでnew Date('2022-07-28')すると2022-07-27 17:00になってしまう
    // そうなるとDateRangeComponentは2022-07-27扱いにしてしまうので強制的にUTCとして扱うようにしている
    startDate: defaultStartDate ? parseDateStringAsUtc(defaultStartDate) : new Date(),
    endDate: defaultEndDate ? parseDateStringAsUtc(defaultEndDate) : addDays(new Date(), 1),
  })

  return (
    <Box background="white">
      <HStack spacing={4} fontSize={20} fontWeight="medium" px={6} py={4} borderColor="gray.30" borderBottomWidth={1}>
        <Text>{formatUtcDateStringInJST(dateRange.startDate, 'M月d日(EEE)')}</Text>
        <Icon as={MdArrowForward} boxSize={6} color="blue.500" />
        <Text>{selectionMode === 'end' ? '選択してください' : formatUtcDateStringInJST(dateRange.endDate, 'M月d日(EEE)')}</Text>
      </HStack>
      <Box
        px={3}
        sx={{
          '.rdrCalendarWrapper': { width: '100%' },
          '.rdrMonth': { width: '50%' },
          '.rdrMonthName': { color: 'black', fontSize: 14, textAlign: 'center', padding: '0 0 16px' },
          '.rdrDayNumber': { fontWeight: 'bold' },
        }}
      >
        <DateRangeComponent
          preventSnapRefocus={true}
          locale={locale}
          dragSelectionEnabled={false}
          rangeColors={['#2C67AC']}
          minDate={selectableDateRange.min}
          maxDate={selectableDateRange.max}
          monthDisplayFormat="yyyy年M月"
          showDateDisplay={false}
          editableDateInputs={true}
          ranges={[{ ...dateRange, key: 'selection' }]}
          months={2}
          direction="horizontal"
          onChange={item => {
            const { startDate, endDate } = item.selection
            // この分岐に入ることは基本的にないが型的な制約で念の為バリデーション
            if (!startDate || !endDate) return

            switch (selectionMode) {
              case 'start': // 開始日を選択し終わったとき
                // endDateの最大を28日前後までに制限する
                if (maxDays && maxStartDate) {
                  setSelectableDateRange({ max: addDays(startDate, maxDays), min: max([addDays(startDate, -maxDays), new Date()]) })
                }
                break
              case 'end': // 終了日を選択し終わったとき
                // 選択可能な日付の範囲をもとに戻す
                setSelectableDateRange({ max: maxStartDate, min: new Date() })
                break
            }

            const diffDays = differenceInDays(endDate, startDate)

            // endDateを選択し終わって、日付が異なっている場合のみ変更通知する
            if (selectionMode === 'end' && diffDays > 0) {
              onChangeStartDate(formatDate(startDate, dateInputFormat))
              onChangeEndDate(formatDate(endDate, dateInputFormat))
            }
            setDateRange({ startDate, endDate })

            // 次このonChangeが呼ばれたときの状態にしておく
            switch (selectionMode) {
              case 'start':
                setSelectionMode('end')
                break
              case 'end':
                setSelectionMode('start')
                break
            }
          }}
        />
      </Box>
    </Box>
  )
}
