import { createIcon } from '@chakra-ui/icon'

export const AirportIcon = createIcon({
  path: (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.63846 9.47834L9.22839 12.0212L12.4636 8.78605L4.74514 8.27083C4.51852 8.26842 4.2992 8.35088 4.13032 8.50201L3.55854 9.07379C3.52573 9.10057 3.50081 9.13578 3.48647 9.17563C3.47212 9.21549 3.46888 9.25849 3.47709 9.30005C3.4853 9.3416 3.50465 9.38014 3.53308 9.41155C3.5615 9.44295 3.59793 9.46604 3.63846 9.47834Z"
        fill="#2C67AC"
      />
      <path
        d="M22.1717 4.37526C22.1279 4.31714 22.0761 4.26541 22.018 4.22156C21.4917 3.75552 18.5504 4.07892 17.6799 4.94827L9.59008 13.0393C9.31956 13.3098 7.844 14.8444 6.74348 16.1773H2.9476C2.71978 16.1902 2.50306 16.2799 2.33278 16.4318L1.761 17.0036C1.72843 17.031 1.70352 17.0664 1.68871 17.1063C1.6739 17.1462 1.6697 17.1892 1.67651 17.2312C1.68333 17.2732 1.70093 17.3128 1.72759 17.3459C1.75425 17.3791 1.78907 17.4048 1.82863 17.4205L4.77975 18.896C4.6076 19.0842 4.48587 19.2649 4.53382 19.3473C4.75024 19.7801 5.47081 19.7801 5.47081 19.7801C7.63251 19.7801 10.8197 17.4131 11.2858 16.952L12.6765 15.5612C12.8612 15.3955 13.0899 15.2865 13.3351 15.2475C13.5802 15.2084 13.8315 15.2409 14.0586 15.3411L18.9021 19.8982C18.972 19.9648 19.0653 20.0013 19.1618 19.9999C19.2584 19.9986 19.3506 19.9594 19.4186 19.8908L19.9903 19.319C20.061 19.2399 20.1095 19.1436 20.1311 19.0398C20.1527 18.9359 20.1466 18.8283 20.1133 18.7276L17.1622 11.0682L21.22 7.01037C22.1582 6.07339 22.575 4.89048 22.1717 4.37526ZM19.7911 6.60336C19.769 6.60912 19.7463 6.61242 19.7235 6.61319C19.6873 6.6119 19.6521 6.6014 19.6211 6.58268C19.5901 6.56396 19.5644 6.53764 19.5464 6.50622C19.4825 6.38325 19.5563 6.28119 19.7333 6.04756C19.9087 5.85126 20.041 5.62048 20.1219 5.37003C20.1136 5.25708 20.0791 5.14762 20.0211 5.05033C19.9858 5.0028 19.9641 4.94667 19.958 4.88782C19.9519 4.82897 19.9618 4.76957 19.9866 4.71587C20.0147 4.66679 20.0533 4.62456 20.0996 4.59223C20.146 4.55991 20.199 4.5383 20.2547 4.52896C20.4713 4.46513 20.6954 4.42999 20.9212 4.42444C21.1757 4.42444 21.3417 4.43551 21.4659 4.5597C21.5261 4.63114 21.5662 4.71728 21.5822 4.80932C21.5982 4.90135 21.5895 4.99598 21.5569 5.08353C21.4055 5.45956 21.1661 5.79382 20.8589 6.05826C20.5517 6.3227 20.1855 6.50963 19.7911 6.60336Z"
        fill="#0069B2"
      />
    </svg>
  ),
})
